import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  PortalUser,
  Report,
  ReportHeader,
  ReportHeaderSummary,
  ResultBundle,
} from '@patient-ui/shared/models';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';

@Injectable({
  providedIn: 'root',
})
export class ResultsService {
  constructor(
    private readonly http: HttpClient,
    private envService: EnvironmentService
  ) {}

  getDashboardResults(linkedId?: string): Observable<ReportHeaderSummary> {
    const linkedAccountsUrlSegment = this.getLinkedAccountsURlSegment(linkedId);
    const url = `${this.envService.baseUrl}/protected/patients/current${linkedAccountsUrlSegment}/results/headers/summary`;
    return this.http.get<ReportHeaderSummary>(url);
  }

  getResultHeaders(): Observable<ReportHeader[]> {
    const linkedAccountsUrlSegment = this.getLinkedAccountsURlSegment();
    const url = `${this.envService.baseUrl}/protected/patients/current${linkedAccountsUrlSegment}/results/headers/all`;
    return this.http.get<ReportHeader[]>(url);
  }

  getResultDetail(resultId: string, patient?: PortalUser): Observable<Report> {
    let linkedAccountsUrlSegment = '';
    if (patient && patient.isPrimary === 'dependent' && patient.id !== 0) {
      linkedAccountsUrlSegment = this.getLinkedAccountsURlSegment(
        patient.recipientLinkedId ? patient.recipientLinkedId : patient.linkedId
      );
    }
    const url = `${this.envService.baseUrl}/protected/patients/current${linkedAccountsUrlSegment}/results/${resultId}`;
    return this.http.get<Report>(url);
  }

  getResultPdf(resultId: string, patient?: PortalUser): Observable<Blob> {
    let linkedAccountsUrlSegment = '';
    if (patient && patient.isPrimary === 'dependent' && patient.id !== 0) {
      linkedAccountsUrlSegment = this.getLinkedAccountsURlSegment(
        patient.recipientLinkedId ? patient.recipientLinkedId : patient.linkedId
      );
    }
    const resultPdfUrl = `${this.envService.baseUrl}/protected/patients/current${linkedAccountsUrlSegment}/results/${resultId}/pdf`;
    return this.http.get(resultPdfUrl, {
      headers: new HttpHeaders({
        Accept: 'application/pdf',
      }),
      responseType: 'blob',
    });
  }

  getResultTrends(earliestDate: string): Observable<ResultBundle[]> {
    const resultTrendsUrl = `${this.envService.baseUrl}/protected/patients/current/linkedAccounts/results/resultTrends?collectionStartDate=${earliestDate}`;
    return this.http.get<ResultBundle[]>(resultTrendsUrl, {
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
    });
  }

  private getLinkedAccountsURlSegment(linkedId?: string): string {
    return `/linkedAccounts${linkedId ? `/${linkedId}` : ''}`;
  }
}
