<div class="row no-gutters">
  <div class="col-auto d-flex flex-column justify-content-between">
    <ui-map-marker [color]="isWalgreens ? 'red' : 'blue'" class="mb-1 d-inline-flex justify-content-center">{{ number }}</ui-map-marker>
    <div [class.invisible]="!isBusy" class="small fw-bold text-uppercase busy-orange">Busy</div>
  </div>
  <div class="col ps-4 d-flex flex-column justify-content-between">
    <address class="mb-1 pt-md-2">
      <div *ngFor="let addressLine of address">{{ addressLine }}</div>
    </address>
    <div class="small fw-bold d-flex align-items-end">
      <span *ngIf="distanceMi !== null" [class.me-4]="isWalgreens">{{ distanceMi | number: '1.0-1' }} mi away</span>
      <span *ngIf="isWalgreens" class="walgreens text-hide d-inline-block">Walgreens</span>
    </div>
  </div>
</div>
