<ng-template #modalPortalIdle let-modal>
  <div class="modal-header">
    <h2 ngbAutofocus class="modal-title flex-fill text-center mt-6">Session Expiring</h2>
  </div>
  <div class="modal-body">
    <div class="custom-modal-body">
      <p>
        You have been inactive for several minutes. For your security, your session will expire soon and you will be signed out of your
        account.
      </p>
      <p>Choose to <span class="fw-bold">Stay Signed</span> In to continue, click <span class="fw-bold">Sign Out</span> if you're done.</p>
    </div>
  </div>
  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-link ms-n4" (click)="modal.close('exit')">Sign Out</button>
    <button type="button" class="btn btn-primary" (click)="close()">Stay Signed In</button>
  </div>
</ng-template>
